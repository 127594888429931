import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../../components/Sidebar";
import InternalMeetingTable from "./InternalMeetingTable";
import { Tabs } from "antd";
import DocumentList from "./DocumentList";
function InternalMeeting() {
  const [activeTab, setActiveTab] = useState("1")
  const onChange = (key) => {
    console.log(key);
    setActiveTab(key)
  };
  const items = [
    {
      key: '1',
      label: ' Primary Research Transcripts',
      children: <InternalMeetingTable />,
    },
    {
      key: '2',
      label: 'Secondary Research Reports',
      children: <DocumentList activeTab={activeTab} />
    },
  ];
  return (
    <>
      <Helmet>
        <title>{"Nextyn | Internal Meetings"}</title>

        {/* <script src="//in.fw-cdn.com/30634560/343565.js" chat="true"></script> */}
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              <div>
                <div className="bk-div float-start w-100">
                  <div className="body-expart-div">
                    <Tabs activeKey={activeTab} items={items} onChange={onChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default InternalMeeting;
