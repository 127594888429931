import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar'
import { List, Spin, Tabs } from 'antd'

const DoNotContactList = () => {
    const [tab, setTab] = useState("1")
    const data = [
        "Walmart Inc.",
        "RTX Corporation(Pratt & Whitney)",
        "United Technologies Corporation",
        "Apple Inc.",
        "Winzo Games Pvt.Ltd."
    ]
    const existingClientList = [
        "917 Ventures",
        "A / B Consulting",
        "A91 Partners",
        "Aconnect",
        "Adkit",
        "Airmeet",
        "Alliance Consulting",
        "Allied Market Research",
        "Alton Aviation",
        "AMR International",
        "Analysys mason",
        "Apax Partners",
        "Apposite Capital LLP",
        "Aranca",
        "Arches",
        "Arkwright",
        "Atlatl",
        "Avasant",
        "Avendus",
        "Bace VC",
        "BalanceHero",
        "BDA",
        "Benori Knowledge",
        "Bertel O.Steen",
        "BlackBox Research",
        "Blackridge Research",
        "BlueQuark Research and Consulting",
        "Cairneagle",
        "Cetas Healthcare",
        "Chryscapital",
        "Circulate Capital",
        "Clarus Capital 1",
        "Clear(Tax)",
        "Cognition Solutions",
        "COI Partners",
        "Consverge",
        "Context Consulting",
        "Corormandel International",
        "Corporate Value",
        "Creador",
        "Cred Club",
        "Crest Optics",
        "CVA",
        "CX Partners",
        "Decibio LLC",
        "Deloitte",
        "Deloitte UK",
        "Dream Sports",
        "Dream11",
        "DSS +",
        "Ducker",
        "Early Charm Ventures",
        "Easebuzz",
        "EMK Capital",
        "Emkay Global",
        "Engie Impact",
        "Enhance Fitness",
        "Eon Consulting",
        "Ergo",
        "Euro Group Consulting",
        "Evalueserve",
        "Everest Group",
        "Explore Equity",
        "Fairmoney",
        "Flipkart",
        "Food Strategy Associates",
        "Fortune business insights",
        "Frost & Sullivan",
        "Fuld & Company",
        "G - Cubed Ventures",
        "Glasgow Insights",
        "Global Angle",
        "Global Data",
        "Global Ventures and Tarek Mounir, Enhance Fitness",
        "Godrej CP",
        "Grant Thornton",
        "Graph Strategy",
        "Haus Consulting",
        "HConnect",
        "Heraeus",
        "Hex Advisory",
        "HiiRoc",
        "Iac Partners",
        "Incite Global",
        "Indrive Ventures",
        "Innovaccer",
        "Intelligentsia",
        "Jiva",
        "Jungle VC",
        "Kae",
        "Kline & Company",
        "KoreFusion",
        "Kotak PE",
        "KPMG",
        "LEK Consulting",
        "Mach49",
        "Madison ",
        "Mahindra",
        "Mana Ventures",
        "Mandala Consulting",
        "Market Mirror",
        "Market Research Future",
        "Markets & Markets",
        "MarkNtel Advisors LLP",
        "Meesho",
        "Meidata",
        "Mergen Compass",
        "MoEngage",
        "Mordor Intellignce",
        "Navi Technologies",
        "Netscribes",
        "Nice",
        "Nomura Research Institute",
        "Nosara Capital",
        "NTX Services",
        "Ola",
        "Oliver Wyman",
        "Ollen Group Consulting",
        "Oman Investment Authority",
        "Oriosity",
        "Oxentia",
        "Panthera",
        "Paragon Partners",
        "Paytm",
        "PGA / 1Lattice",
        "PGP",
        "Philip Morris International",
        "Playfair Capital",
        "Praxis Global Alliance",
        "Praxisga",
        "Prismane Consulting",
        "Proactive Worldwide",
        "PWC",
        "Raphsody VP",
        "Ratnabali",
        "Ravenry",
        "Red Chalk",
        "Redseer Consulting",
        "Redseer Consulting Thailand",
        "Rhapsody Venture Partners",
        "Rockbridge Growth Equity ",
        "Rosearch",
        "Sciffy Academy",
        "Scrutiny Global",
        "SG Analytics",
        "Sia Partners",
        "Singular",
        "Smart Connect Research",
        "Smart Solve",
        "Spire Research",
        "Stellarix",
        "Storskogen",
        "Stryber",
        "Studio Ab",
        "T4.Ai",
        "Talaypu",
        "Tavasyam Research",
        "Technovaglobal",
        "Thaioil Group",
        "The Smart Cube",
        "Tikehau Capital",
        "Tinmen",
        "TOP Ventures",
        "TransformationX",
        "TrueNorth",
        "Two Degrees Technologies",
        "Tyton Partners",
        "Universal Consulting",
        "Vector Consutling",
        "Videoverse / Magnify.ai",
        "Vivo",
        "VK Team",
        "Walkme",
        "Walla",
        "Wallfort PMS",
        "Wavestone",
        "Weare MTM",
        "WGroup",
        "White Space Strategy",
        "xto10x",
        "Yamada Consulting and Spire",
        "YCP Solidiance",
        "Zinnov",
        "Zolve",

    ]

    const TabList = [
        {
            key: '1',
            label: <div className="d-flex justify-content-between align-items-center">
                <h6>Do Not Contact (DNC)</h6>
            </div>,
            children: <>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item >
                            {index + 1}. {item}
                        </List.Item>
                    )}
                /></>,
        },
        {
            key: '2',
            label: <div className="d-flex justify-content-between align-items-center">
                <h6>Existing Clients (Do Not Contact)</h6>
            </div>,
            children: <>
                <List
                    itemLayout="horizontal"
                    dataSource={existingClientList}
                    renderItem={(item, index) => (
                        <List.Item >
                            {index + 1}. {item}
                        </List.Item>
                    )}
                /></>,
        },

    ];

    const onChange = (key) => {
        setTab(key)
    };
    return (
        <>
            <Helmet>
                <title>{"Nextyn | Home"}</title>
            </Helmet>
            <div className="page-wrapper chiller-theme toggled">
                <div className="main-sec d-lg-flex">
                    <Sidebar />
                </div>
                <div className="text-center loginScreenLinkedin">
                    <Spin size="large" />
                </div>
                <main className="body-total content-wrapper float-start w-100 dashboardPage">
                    <div>
                        <div className="d-block d-lg-block">
                            <div>
                                <div className="dasb-div bk-div float-start w-100">
                                    <div className="body-expart-div">
                                        <Tabs activeKey={tab} items={TabList} onChange={onChange} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div >
        </>
    )
}

export default DoNotContactList
