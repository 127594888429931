import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    openAddDocumentModal: false,
    type: "create",
    documentList: [],
    selectedDoc: {},
    count: 0,
    industryOptions: [],
    subIndustryOptions: [],
    fileList: [],
    deletedfileList: [],
    tempIndustry: "",
    tempSubIndustry: "",
    open: false,
    opensub: false,
    key: "",
    formvalues: {
        "keywords": [],
        "title": "",
        "country": [],
        "region": [],
        "industry": [],
        "subIndustry": [],
        "attachement": null //only to set for formfield
    },
    isOpenDelete: false,
    isLoadingSubmit: false,
    isLoadingDelete: false,
    page: 1,
    limit: 10

}

const documentListSlice = createSlice({
    name: "documentList",
    initialState: initialState,
    reducers: {
        setOpenAddDocumentModal: (state, { payload }) => {
            state.openAddDocumentModal = payload
        },
        setFileList: (state, { payload }) => {
            state.fileList = payload
        },
        setFormValues: (state, { payload }) => {
            state.formvalues = payload
        },
        setOpen: (state, { payload }) => {
            state.open = payload
        },
        setKey: (state, { payload }) => {
            state.key = payload
        },
        setIsOpenDelete: (state, { payload }) => {
            state.isOpenDelete = payload
        },
        setOpenSub: (state, { payload }) => {
            state.opensub = payload
        },
        setdeletedFileList: (state, { payload }) => {
            state.deletedfileList = payload
        },
        setIndustryOptions: (state, { payload }) => {
            state.industryOptions = payload
        },
        setSubIndustryOptions: (state, { payload }) => {
            state.subIndustryOptions = payload
        },
        setType: (state, { payload }) => {
            state.type = payload
        },
        setTempIndustry: (state, { payload }) => {
            state.tempIndustry = payload
        },
        setTempSubIndustry: (state, { payload }) => {
            state.tempSubIndustry = payload
        },
        setIsLoadingSubmit: (state, { payload }) => {
            state.isLoadingSubmit = payload
        },
        setIsLoadingDelete: (state, { payload }) => {
            state.isLoadingDelete = payload
        },
        setPage: (state, { payload }) => {
            state.page = payload
        },
        setLimit: (state, { payload }) => {
            state.limit = payload
        },
        setDocumentList: (state, { payload }) => {
            state.documentList = payload
        },
        setCountData: (state, { payload }) => {
            state.count = payload
        },
        setSelectedDoc: (state, { payload }) => {
            state.selectedDoc = payload
        },
        clearAll: (state) => {
            state.formvalues = {
                "keywords": [],
                "title": "",
                "country": [],
                "region": [],
                "industry": [],
                "subIndustry": [],
                "attachement": null
            }
            state.fileList = []
            state.deletedfileList = []
            state.tempIndustry = ""
            state.tempSubIndustry = ""
            state.open = false
            state.opensub = false
        }
    }

})
export const documentListReducer = documentListSlice.reducer
export const { setOpenAddDocumentModal, setIndustryOptions, setSubIndustryOptions,
    setType, setFileList, setdeletedFileList, setTempIndustry, setTempSubIndustry, setOpen, setKey,
    setOpenSub, setIsLoadingDelete, setFormValues, clearAll, setIsLoadingSubmit, setPage, setLimit, setDocumentList, setCountData, setSelectedDoc, setIsOpenDelete } = documentListSlice.actions

