import { Modal, Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import ENV from '../../../Constants/env';
import _ from "lodash";
const ProjectExpertListModal = ({
    setIsOpenExpertListModale,
    isOpenExpertListModal, setIsOpen, setExpertId
}) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();
    let project_id = query.get("project_id");
    const [expertList, setExpertList] = useState([])
    const navigate = useNavigate()
    const data = expertList?.map((exp) => ({
        expert_name: <span onClick={() => {
            setExpertId(exp?._id?.$oid)
            setIsOpen(true)
        }}>{exp?.name}</span>,
        country: exp?.country,
        company: <Tooltip title={exp?.current_company}>{_.truncate(exp?.current_company, { length: 30, omission: "..." })}</Tooltip>,
        designation: <Tooltip title={exp?.current_designation}>{_.truncate(exp?.current_designation, { length: 30, omission: "..." })}</Tooltip>,
        expert_rate: <>{exp?.hourly_rate || exp?.hourly_rate_call_cunsltn || "-"} {(exp?.hourly_rate || exp?.hourly_rate_call_cunsltn) ? exp?.hourly_currency : ""}</>,
        key: exp?._id?.$oid
    }))

    const columns = [

        {
            title: "Expert Name",
            dataIndex: "expert_name",
            key: "name",
            width: "20%"
        },
        {
            title: "Country",
            dataIndex: "country",
            key: "country",
            width: "10%"
        },
        {
            title: "Company",
            dataIndex: "company",
            key: "company",
            width: "30%"
        },
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
            width: "30%"
        },

        {
            title: "Expert Rate",
            dataIndex: "expert_rate",
            key: "expert_rate",
            width: "10%"
        },

        {
            title: "Action",
            dataIndex: "_id",
            key: "action",
            width: "10%",
            render: (_id, record) => (
                <div className="d-flex align-items-center justify-content-center gap-2 g-2">
                    <button
                        className="viewButton btn"
                        onClick={() => {
                            setExpertId(record?.key)
                            setIsOpen(true)
                        }}
                    >
                        View
                    </button>
                    <button
                        className="viewButton btn"
                        onClick={() => {
                            // navigate(
                            //     `/dashboard/addExpert?project_id=${project_id}&expertID=${record?.key}&redirect=team`
                            // );
                        }}
                    >
                        Add
                    </button>
                </div>
            ),
        },
    ];
    const getRecommendedExperts = React.useCallback(async () => {
        const formData = new FormData()
        formData.append("id", project_id)
        // formData.append("db", ENV.BASE_URL === "https://dev.api.platform.nextyn.com" ? "mongo" : "vector")
        formData.append("db", "vector")
        try {
            const res = await axios.post(ENV.SUGGESTED_EXPERT_URL, formData);
            return res
        } catch (error) {
            console.error('error: ', error);
        }
    }, [project_id])

    const { isLoading } = useSWR(["recommended_experts", isOpenExpertListModal], () => isOpenExpertListModal ? getRecommendedExperts() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res?.data) {
                let parseData = JSON.parse(res?.data)
                setExpertList(parseData)
            }

        }
    })
    return (
        <Modal
            open={isOpenExpertListModal}
            footer={false}
            onCancel={() => setIsOpenExpertListModale(false)}
            title="Recommended Experts"
            width={1500}
        >
            <div>
                {/* <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item >
                            <List.Item.Meta
                                avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                                title={<a href="https://ant.design">{item.title}</a>}
                                description="Ant Design, a design language for background applications, is refined by Ant UED Team"

                            />
                            <button
                                className="viewButton btn"
                                onClick={() => {
                                    navigate(
                                        `/dashboard/addExpert?project_id=${"ddasd"}&expertID=${"asdada"}&redirect=team`
                                    );
                                }}
                            >
                                Add
                            </button>
                        </List.Item>
                    )}
                /> */}
                <Table
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                    loading={isLoading}
                    size='middle'
                    scroll={{
                        x: 800
                    }}
                />
            </div>
        </Modal>
    )
}

export default ProjectExpertListModal