import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    inputs: [
        {
            id: "1",
            title: "",
            company: "",
            startDate: "",
            endDate: "",
            current_show_check: "yes",
            till_present: "no",
        },
    ],
    addData: {
        expert_id: "",
        email: null,
        expert_name: "",
        primary_expert_rate: "",
        expert_primary_rate_currency: "USD",
        industry_experience: "",
        inustry_or_area_of_expertise: "",
        submit: "",
        link: "",
        consulting_rate: "",
        expertCurrency: "USD",
        hourly_consulting_rate: "",
        expert_client_Currency: "USD",
        current_location: "",
        profile_number: null,
    },
    overview: "",
    inputsTwo: [{
        id: "1",
        titlePrev: "",
        companyPrev: "",
        startDatePrev: "",
        endDatePrev: "",
        previous_show_check: "yes",
    }],
    experts: [],
    loading: false,
    spinner: false,
    filteredIndustOptions: [],
    industOption: "",
    open: false,
    resExpert: false,
    customIndustry: "",
    activePrev: 0,
    activeCurr: 0
}

const addNewExpertSectionSlice = createSlice({
    name: "addNewExpertSectionSlice",
    initialState: initialState,
    reducers: {
        setInputsTwo: (state, { payload }) => {
            state.inputsTwo = payload
        },
        setActivePrev: (state, { payload }) => {
            state.activePrev = payload
        },
        setActiveCurr: (state, { payload }) => {
            state.activeCurr = payload
        },
        setInputss: (state, { payload }) => {
            state.inputs = payload
        },
        setOpen: (state, { payload }) => {
            state.open = payload
        },
        setAddData: (state, { payload }) => {
            state.addData = payload
        },
        setOverview: (state, { payload }) => {
            state.overview = payload
        },
        setCustomIndustry: (state, { payload }) => {
            state.customIndustry = payload
        },
        setResExpert: (state, { payload }) => {
            state.resExpert = payload
        },
        setExperts: (state, { payload }) => {
            state.experts = payload
        },
        setLoading: (state, { payload }) => {
            state.loading = payload
        },
        setSpinner: (state, { payload }) => {
            state.spinner = payload
        },
        setFilteredIndustOptions: (state, { payload }) => {
            state.filteredIndustOptions = payload
        },
        setFilteredIndust: (state, { payload }) => {
            state.industOption = payload
        },
        resetFields: (state) => {
            state.addData = {
                expert_id: "",
                email: null,
                expert_name: "",
                primary_expert_rate: "",
                expert_primary_rate_currency: "USD",
                industry_experience: "",
                inustry_or_area_of_expertise: "",
                submit: "",
                link: "",
                consulting_rate: "",
                expertCurrency: "USD",
                hourly_consulting_rate: "",
                expert_client_Currency: "USD",
                overview: "",
                current_location: "",
                profile_number: null,
            }
            state.overview = ""
            state.inputs = [
                {
                    id: "1",
                    title: "",
                    company: "",
                    startDate: "",
                    endDate: "",
                    current_show_check: "yes",
                    till_present: "no",
                },
            ]
            state.inputsTwo = [{
                id: "1",
                titlePrev: "",
                companyPrev: "",
                startDatePrev: "",
                endDatePrev: "",
                previous_show_check: "yes",
            }]
            state.experts = []
            state.loading = false
            state.spinner = false
            state.filteredIndustOptions = []
            state.industOption = ""
            state.open = false
            state.resExpert = false
            state.customIndustry = ""
            state.activePrev = 0
            state.activeCurr = 0

        }
    }

})
export const addNewExpertFormReducer = addNewExpertSectionSlice.reducer
export const { setInputsTwo, setExperts, setInputss, setLoading, setSpinner, setFilteredIndustOptions, setFilteredIndust, setOpen, setResExpert, setCustomIndustry, setAddData, setActiveCurr, setActivePrev, resetFields, setOverview } = addNewExpertSectionSlice.actions

