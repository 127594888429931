import React, { useEffect } from "react";
import {
    Button,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Select,
    Space,
    Spin,
    Tooltip,
    Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import upload_icon from "../../../../assests/images/upload_icon.png";
import { clearAll, setdeletedFileList, setFileList, setFormValues, setOpen, setOpenAddDocumentModal, setOpenSub, setTempIndustry, setTempSubIndustry, setType } from "../Slice/documentList.slice";
import useDocumentList from "../hooks/useDocumentList";
import { Country } from "country-state-city";
const { Dragger } = Upload;
const DocumentUploaderModal = () => {

    const countries = Country?.getAllCountries();
    const { open, opensub, openAddDocumentModal, formvalues, type, industryOptions, subIndustryOptions, deletedfileList, fileList, tempIndustry, tempSubIndustry, isLoadingSubmit, selectedDoc } = useSelector(
        (state) => state.documentList
    );

    const dispatch = useDispatch();
    const { debounceFetchSubIndustries, debounceFetchIndustries, uploadDocument, form, updateDocument, onlyOnce } = useDocumentList()

    //set initial value as well as when form value change

    useEffect(() => {
        if (!onlyOnce.current && openAddDocumentModal) {
            onlyOnce.current = true
            if (selectedDoc?._id) {
                let obj = {
                    "keywords": selectedDoc?.keyword,
                    "title": selectedDoc?.title,
                    "country": selectedDoc?.country || [],
                    "region": selectedDoc?.region || [],
                    "industry": selectedDoc?.industry || [],
                    "subIndustry": selectedDoc?.sub_industry || [],
                    "attachement": selectedDoc?.documents?.map((doc) => ({
                        uid: doc?.s3key, // unique identifier for the file
                        name: doc?.file_original_name, // name of the file
                        status: "done", // status 'done' because it's already uploaded
                        url: doc?.s3url, // use the link you already have for the file
                    })) //only to set for formfield
                }
                form.setFieldsValue(obj);
                dispatch(setType("update"))
                dispatch(setFormValues(obj))
                dispatch(setFileList(selectedDoc?.documents?.map((doc) => ({
                    uid: doc?.s3key, // unique identifier for the file
                    name: doc?.file_original_name, // name of the file
                    status: "done", // status 'done' because it's already uploaded
                    url: doc?.s3url, // use the link you already have for the file
                }))))
            } else {
                dispatch(setType("create"))
                dispatch(setFormValues(formvalues))
                form.setFieldsValue(formvalues);
            }
        }

    }, [dispatch, form, formvalues, onlyOnce, openAddDocumentModal, selectedDoc?._id, selectedDoc?.country, selectedDoc?.documents, selectedDoc?.industry, selectedDoc?.keyword, selectedDoc?.region, selectedDoc?.sub_industry, selectedDoc?.title]);

    const handleDropdownVisibleChange = (isOpen) => {
        dispatch(setOpen(isOpen));
        if (isOpen) {
            debounceFetchIndustries("")
        }
    };
    const handleDropdownVisibleChangeSub = (isOpen) => {
        dispatch(setOpenSub(isOpen));
        if (isOpen) {
            debounceFetchSubIndustries("")
        }
    };
    return (
        <Modal
            title={
                <span className="modalHeader">
                    {type === "create" ? "Upload Documents" : "Update Documents"}
                </span>
            }
            footer={false}
            open={openAddDocumentModal}
            onOk={() => dispatch(setOpenAddDocumentModal(false))}
            onCancel={() => {
                dispatch(setOpenAddDocumentModal(false));
                dispatch(clearAll());
                onlyOnce.current = false
                form.resetFields();
            }}
            width={680}
            centered
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={formvalues}
                onFinish={() => {
                    if (type === "create") {
                        uploadDocument()
                    } else {
                        updateDocument(selectedDoc?._id)
                    }
                }}
            >
                <Row>
                    <Col>
                        <Form.Item
                            label="Document Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: "Document Title is required",
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder="Add Title"
                                name="title"
                                value={formvalues.title}
                                onChange={(e) => {
                                    let obj = {
                                        ...formvalues,
                                        title: e.target.value,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("title", e.target.value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Item
                            label="Industries"
                            name="industry"
                            rules={[
                                { required: true, message: "Industries are required" },
                            ]}
                        >
                            <Select
                                size="large"
                                mode="tags"
                                onSearch={debounceFetchIndustries}
                                value={formvalues?.industry}
                                allowClear
                                open={false}
                                onDropdownVisibleChange={(isOpen) => handleDropdownVisibleChange(isOpen)}
                                placeholder="Select Industries"
                                onChange={(newValue) => {
                                    let newVal = []
                                    newValue?.forEach((val) => {
                                        if (val?.includes(",")) {
                                            val?.split(",")?.map(item => newVal?.push(item?.trim())); // Split and trim any whitespace
                                        } else {
                                            newVal?.push(val)
                                        }
                                    })
                                    let obj = {
                                        ...formvalues,
                                        industry: newVal,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("industry", newVal);
                                }}
                                style={{
                                    width: "100%",
                                }}

                            // dropdownRender={
                            //     (menu) => (
                            //         <>
                            //             {/* {fetching ? (
                            //                     <span
                            //                         className="d-flex align-items-center justify-content-center"
                            //                         style={{ height: 40 }}
                            //                     >
                            //                         <Spin size="small" />
                            //                     </span>
                            //                 ) : ( */}
                            //             <>
                            //                 {menu}
                            //                 <Divider style={{ margin: "8px 0" }} />
                            //                 <Space.Compact
                            //                     block
                            //                     style={{ padding: "0 8px 4px", width: "100%" }}
                            //                     className="zum_meeting_space"
                            //                 >
                            //                     <Tooltip
                            //                         title={"For Unregistered Experts Only"}
                            //                         overlayStyle={{ zIndex: 2000 }}
                            //                     >
                            //                         <Input
                            //                             placeholder="Please enter Industry"
                            //                             // ref={inputRef}
                            //                             value={tempIndustry}
                            //                             width={100}
                            //                             onChange={(e) => {
                            //                                 dispatch(setTempIndustry(e.target.value))
                            //                             }}
                            //                             onKeyDown={(e) => e.stopPropagation()}
                            //                         />
                            //                     </Tooltip>

                            //                     <Button
                            //                         type="text"
                            //                         icon={<PlusOutlined />}
                            //                         onClick={(e) => {
                            //                             let obj = {
                            //                                 ...formvalues,
                            //                                 industry: [...formvalues.industry, tempIndustry],
                            //                             };
                            //                             dispatch(setFormValues(obj));
                            //                             form.setFieldValue("industry", [...formvalues.industry, tempIndustry]);
                            //                             dispatch(setOpen(false));
                            //                             dispatch(setTempIndustry(""));
                            //                         }}
                            //                     >
                            //                         Add
                            //                     </Button>


                            //                 </Space.Compact>
                            //             </>
                            //         </>
                            //     )
                            // }
                            >
                                {industryOptions?.map((option) => (
                                    <Select.Option key={option?._id} value={option?.label}>
                                        {option?.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Sub Industries"
                            name="subIndustry"

                            rules={[
                                { required: true, message: "Sub Industries are required" },
                            ]}
                        >
                            <Select
                                size="large"
                                mode="tags"
                                allowClear
                                onSearch={debounceFetchSubIndustries}
                                value={formvalues?.subIndustry}
                                open={false}
                                onDropdownVisibleChange={(isOpen) => handleDropdownVisibleChangeSub(isOpen)}
                                placeholder="Select Sub Industry"
                                onChange={(newValue) => {
                                    let newVal = []
                                    newValue?.forEach((val) => {
                                        if (val?.includes(",")) {
                                            val?.split(",")?.map(item => newVal?.push(item?.trim())); // Split and trim any whitespace
                                        } else {
                                            newVal?.push(val)
                                        }
                                    })
                                    let obj = {
                                        ...formvalues,
                                        subIndustry: newVal,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("subIndustry", newVal);
                                }}
                                style={{
                                    width: "100%",
                                }}
                            // dropdownRender={
                            //     (menu) => (
                            //         <>
                            //             {/* {fetching ? (
                            //                     <span
                            //                         className="d-flex align-items-center justify-content-center"
                            //                         style={{ height: 40 }}
                            //                     >
                            //                         <Spin size="small" />
                            //                     </span>
                            //                 ) : ( */}
                            //             <>
                            //                 {menu}
                            //                 <Divider style={{ margin: "8px 0" }} />
                            //                 <Space.Compact
                            //                     block
                            //                     style={{ padding: "0 8px 4px", width: "100%" }}
                            //                     className="zum_meeting_space"
                            //                 >
                            //                     <Tooltip
                            //                         title={"For Unregistered Experts Only"}
                            //                         overlayStyle={{ zIndex: 2000 }}
                            //                     >
                            //                         <Input
                            //                             placeholder="Please enter Sub Industry"
                            //                             // ref={inputRef}
                            //                             value={tempSubIndustry}
                            //                             width={100}
                            //                             onChange={(e) => {
                            //                                 dispatch(setTempSubIndustry(e.target.value))
                            //                             }}
                            //                             onKeyDown={(e) => e.stopPropagation()}
                            //                         />
                            //                     </Tooltip>

                            //                     <Button
                            //                         type="text"
                            //                         icon={<PlusOutlined />}
                            //                         onClick={(e) => {
                            //                             let obj = {
                            //                                 ...formvalues,
                            //                                 subIndustry: [...formvalues.subIndustry, tempSubIndustry],
                            //                             };
                            //                             dispatch(setFormValues(obj));
                            //                             form.setFieldValue("subIndustry", [...formvalues.subIndustry, tempSubIndustry]);
                            //                             dispatch(setOpenSub(false));
                            //                             dispatch(setTempSubIndustry(""));

                            //                         }}
                            //                     >
                            //                         Add
                            //                     </Button>


                            //                 </Space.Compact>
                            //             </>
                            //         </>
                            //     )
                            // }
                            >
                                {subIndustryOptions?.map((option) => (
                                    <Select.Option key={option?.key} value={option?.category_name}>
                                        {option?.category_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row>
                    <Col>
                        <Form.Item
                            label="Keywords/tags"
                            name={"keywords"}
                            rules={[{ required: true, message: "Keywords are required" }]}
                        >
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                name="keywords"
                                size="large"
                                mode="tags"
                                allowClear
                                open={false}
                                placeholder="Add Keywords or Tags"
                                value={formvalues?.keywords}
                                onChange={(value) => {
                                    let newVal = []
                                    value?.forEach((val) => {
                                        if (val?.includes(",")) {
                                            val?.split(",")?.map(item => newVal?.push(item?.trim())); // Split and trim any whitespace
                                        } else {
                                            newVal?.push(val)
                                        }
                                    })
                                    let obj = {
                                        ...formvalues,
                                        keywords: newVal,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("keywords", newVal)
                                }}
                                filterOption={(input, option) =>
                                    option?.children
                                        ?.toLowerCase()
                                        .indexOf(input?.toLowerCase()) >= 0
                                }
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: "Country is required",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                value={formvalues.country}
                                style={{ flex: 1, marginRight: "8px" }}
                                placeholder="Add Countries"
                                name="country"
                                mode="tags"
                                open={false}
                                size="large"
                                virtual={false}
                                allowClear
                                onChange={(selectedValue) => {
                                    let newVal = []
                                    selectedValue?.forEach((val) => {
                                        if (val?.includes(",")) {
                                            val?.split(",")?.map(item => newVal?.push(item?.trim())); // Split and trim any whitespace
                                        } else {
                                            newVal?.push(val)
                                        }
                                    })
                                    let obj = {
                                        ...formvalues,
                                        country: newVal,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("country", newVal);
                                }}
                                filterOption={(input, option) =>
                                    option?.props?.children
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            />
                            {/* <>
                                    <Select.Option key={0} value={"Global"} label={"Global"}>
                                        Global
                                    </Select.Option>
                                    <Select.Option key={1} value={"SAARC"} label={"SAARC"}>
                                        SAARC
                                    </Select.Option>
                                    <Select.Option key={2} value={"Asia"} label={"Asia"}>
                                        Asia
                                    </Select.Option>
                                    <Select.Option
                                        key={3}
                                        value={"Middle East"}
                                        label={"Middle East"}
                                    >
                                        Middle East
                                    </Select.Option>
                                    <Select.Option key={4} value={"Europe"} label={"Europe"}>
                                        Europe
                                    </Select.Option>
                                    <Select.Option
                                        key={5}
                                        value={"North America"}
                                        label={"North America"}
                                    >
                                        North America
                                    </Select.Option>
                                    <Select.Option
                                        key={6}
                                        value={"South America"}
                                        label={"South America"}
                                    >
                                        South America
                                    </Select.Option>
                                </>
                                {countries?.map((country, index) => (
                                    <Select.Option
                                        key={index + 8}
                                        value={country?.name}
                                        label={country?.name}
                                    >
                                        {`${country?.name}`}
                                    </Select.Option>
                                ))}
                            </Select> */}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Region"
                            name="region"
                            rules={[
                                {
                                    required: true,
                                    message: "Region is required",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                value={formvalues.region}
                                mode="tags"
                                style={{ flex: 1, marginRight: "8px" }}
                                placeholder="Add Regions"
                                name="region"
                                size="large"
                                open={false}
                                virtual={false}
                                allowClear
                                onChange={(selectedValue) => {
                                    let newVal = []
                                    selectedValue?.forEach((val) => {
                                        if (val?.includes(",")) {
                                            val?.split(",")?.map(item => newVal?.push(item?.trim())); // Split and trim any whitespace
                                        } else {
                                            newVal?.push(val)
                                        }
                                    })
                                    let obj = {
                                        ...formvalues,
                                        region: newVal,
                                    };
                                    dispatch(setFormValues(obj));
                                    form.setFieldValue("region", newVal);
                                }}
                                filterOption={(input, option) =>
                                    option?.props?.children
                                        ?.toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            />
                            {/* <>
                                    <Select.Option key={0} value={"Global"} label={"Global"}>
                                        Global
                                    </Select.Option>
                                    <Select.Option key={1} value={"SAARC"} label={"SAARC"}>
                                        SAARC
                                    </Select.Option>
                                    <Select.Option key={2} value={"Asia"} label={"Asia"}>
                                        Asia
                                    </Select.Option>
                                    <Select.Option
                                        key={3}
                                        value={"Middle East"}
                                        label={"Middle East"}
                                    >
                                        Middle East
                                    </Select.Option>
                                    <Select.Option key={4} value={"Europe"} label={"Europe"}>
                                        Europe
                                    </Select.Option>
                                    <Select.Option
                                        key={5}
                                        value={"North America"}
                                        label={"North America"}
                                    >
                                        North America
                                    </Select.Option>
                                    <Select.Option
                                        key={6}
                                        value={"South America"}
                                        label={"South America"}
                                    >
                                        South America
                                    </Select.Option>
                                </>
                                {countries?.map((country, index) => (
                                    <Select.Option
                                        key={index + 8}
                                        value={country?.name}
                                        label={country?.name}
                                    >
                                        {`${country?.name}`}
                                    </Select.Option>
                                ))}
                            </Select> */}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item rules={[{ required: true, message: "Documents are required" }]} label="Documents" name="attachement">
                        <Dragger
                            className="w-100 document_uploader"
                            action={false}
                            maxCount={1}
                            customRequest={async ({ file, onSuccess, onError }) => {
                                // Simulate an upload request
                                setTimeout(() => {
                                    onSuccess("ok"); // Simulate successful upload
                                }, 1000);
                            }}
                            beforeUpload={async (file) => {
                                const isLt10M = file.size / 1024 / 1024 < 10;
                                if (!isLt10M) {
                                    message.error({
                                        content: "File must be smaller than 10MB",
                                        style: {
                                            fontSize: "18px", // Increase the font size
                                            padding: "20px", // Increase the padding
                                        },
                                    });
                                    form.setFields([
                                        {
                                            name: "attachement",
                                            errors: [`File must be smaller than 10MB.`],
                                        },
                                    ]);
                                }
                                const isPNG = file.type === "image/png";
                                const isJPEG = file.type === "image/jpeg";
                                const isPDF = file.type === "application/pdf";
                                const isCSV = file.type === "text/csv";
                                if (!isPNG && !isJPEG && !isPDF && !isCSV) {
                                    form.setFields([
                                        {
                                            name: "attachement",
                                            errors: [`Please upload a file of a PDF, PNG, CSV or JPEG format.`],
                                        },
                                    ]);
                                }
                                return isPNG || isJPEG || isPDF || isCSV || isLt10M || Upload.LIST_IGNORE;
                            }}
                            onRemove={async (file) => {
                                dispatch(setdeletedFileList([...deletedfileList, file]));
                                dispatch(setFileList(fileList?.filter((f) => f.uid !== file.uid)));
                            }}
                            onChange={({ fileList }) => {
                                let list = fileList
                                    ?.map((file) => {
                                        if (file?.size) {
                                            const isLt10M = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB
                                            const isPNG = file.type === "image/png";
                                            const isJPEG = file.type === "image/jpeg";
                                            const isSpreadsheet = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                                            const isWordsheet = file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
                                            const isPDF = file.type === "application/pdf";
                                            const isCSV = file.type === "text/csv";
                                            if ((isPNG || isJPEG || isPDF || isWordsheet || isSpreadsheet || isCSV) && isLt10M) {
                                                return file
                                            }
                                        } else {
                                            return file;
                                        }
                                    })
                                    ?.filter((li) => li !== undefined);
                                dispatch(setFileList(list))
                            }}
                            multiple={true}
                            fileList={fileList}
                        >
                            <img
                                src={upload_icon}
                                style={{ width: "60px" }}
                                alt="Upload Icon"
                            />
                            <p className="ant-upload-text pb-0 mt-4">
                                Please upload all relevant documents regarding the project
                            </p>
                            <small className="text-muted">
                                Maximum file size 10MB each
                            </small>
                        </Dragger>
                    </Form.Item>
                </Row>
                <div className="d-flex justify-content-end align-items-center">
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            class="secondaryBttn btn"
                            type="button"
                            onClick={() => {
                                dispatch(setOpenAddDocumentModal(false));
                                dispatch(clearAll());
                                onlyOnce.current = false
                                form.resetFields();
                            }}
                        >
                            Close
                        </button>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <button
                            className="viewButton btn ms-2"
                            type="submit"
                            htmlType="submit"
                        >
                            {type === "create" ? <>Upload {isLoadingSubmit && <Spin
                                size="small"
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            color: "white",
                                            paddingRight: 5,
                                        }}
                                    />
                                }
                            />}</> : "Update"}
                        </button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
}

export default DocumentUploaderModal;
