import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Input, Spin, Modal, Divider, Select, notification, Checkbox, List, Table } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Row } from "react-bootstrap";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import API_URL from "../../Constants/api-path";
import axios from "axios";
import { useLocation } from "react-router-dom";
import debounce from "lodash/debounce";

const { Option } = Select;

export const ProjectListModal = ({
    isOpenProject,
    setIsOpenProject,
    expertProjectList,
    getExpertProfileDetails
}) => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [projectList, setProjectList] = useState([]);
    const [projectListCount, setProjectListCount] = useState([]);
    const [managerFilterList, setManagerFilterList] = useState([]);
    const [teamMemberList, setTeamMemberList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [selectedOptions, setSelectedOptions] = useState({
        project_name: "",
        client_name: [],
        added_by: [],
        manager_id: [],
        date: [],
        page: 1,
        key: ""
    });
    const [loading, setLoading] = useState(false);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();
    let expert_id = query.get("expertId");
    const handleClose = () => {
        setIsOpenProject(false)
        setSelectedRowKeys([])
    };
    // const onSearch = _.debounce((e) => {
    //     const value = e.target.value;
    //     // getProjectListById(value);
    // }, 500)

    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },

    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(API_URL.ADD_PROJECT_IN_EXPERT, {
                "expert_id": expert_id,
                "project_id": selectedRowKeys?.map((pro_id) => {
                    return {
                        project_id: pro_id
                    }
                }),
                "user_id": userData?.data?._id
            })
            setLoading(false);
            setIsOpenProject(false)
            GetProjectList()
            setSelectedRowKeys([])
            getExpertProfileDetails()
            notification.success({
                message: "Expert added for the project",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                style: {
                    backgroundColor: "#2ecc71",
                    color: "#fff !important",
                    border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
            });
        } catch (error) {
            setLoading(false);
            notification.error({
                message: "Expert addition failed",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }

    };

    const GetProjectList = useCallback(async (selectedOptions) => {
        try {
            setLoadingProjects(true)
            let object = {};

            if (selectedOptions?.project_name) {
                object.project_name = selectedOptions?.project_name;
            }

            if (selectedOptions?.client_name) {
                object.client_name = selectedOptions?.client_name;
            }

            if (selectedOptions?.added_by) {
                object.added_by = selectedOptions?.added_by;
            }
            if (selectedOptions?.manager_id) {
                object.manager_id = selectedOptions?.manager_id;
            }
            if (selectedOptions?.page) {
                object.page = selectedOptions?.page;
            }
            if (selectedOptions?.key) {
                object.key = selectedOptions?.key;
            }
            const queryParams = new URLSearchParams(object);
            const res = await fetch(`${API_URL.CLIENT_LIST_PROJECT}/${userData?.data?.email}${queryParams?.toString() ? `?${queryParams?.toString()}` : ""}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                },
            });

            const result = await res.json();
            if (result) {
                setProjectList(result?.data);
                setProjectListCount(result?.countData);
                setManagerFilterList(result?.allManagers)
                setTeamMemberList(result?.allTeams)
                setLoadingProjects(false)
                let idArr = expertProjectList?.map((pr) => pr?.project_id)
                let checked = result?.data?.filter((pro) => idArr?.includes(pro?._id))
                // setChecked(checked?.map((proj) => proj?._id))
                setSelectedRowKeys(checked?.map((proj) => proj?._id))
            } else {
                setLoadingProjects(false)
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
            // setLoading(false);
        } catch (error) {
            // setLoading(false);
            setLoadingProjects(false)
            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
    }, [expertProjectList, userData?.data?.email, userData?.token]);

    const GetClientList = useCallback(
        async (page = 1, selectedOptions) => {
            setLoading(true);
            try {
                let object = {};

                if (selectedOptions?.key) {
                    object.key = selectedOptions?.key;
                }
                const res = await fetch(
                    `${API_URL.CLIENT_LIST}?page=${page}&limit=10&key=${selectedOptions?.key || ""
                    }`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userData?.token}`,
                        },
                    }
                );

                const result = await res.json();
                if (result) {
                    setClientList(result?.data);
                }
            } catch (error) {
                setLoading(false);
                notification.error({
                    message: "Error: Something went wrong server error",
                    icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                    style: {
                        backgroundColor: "#e74c3c",
                        color: "#fff",
                        border: "1px solid #c0392b",
                    },
                    duration: 5,
                    placement: "topRight",
                });
            }
            setLoading(false);
        },
        [userData?.token]
    );

    const callOnce = useRef(false)
    useEffect(() => {
        if (!callOnce.current) {
            callOnce.current = true
            GetProjectList();
            GetClientList()
        }
    }, [GetClientList, GetProjectList]);

    const callSearch = useRef(false)
    useEffect(() => {
        if (callSearch.current) {
            callSearch.current = false
            let handler = setTimeout(async () => {
                GetProjectList({ ...selectedOptions, page: 1 });

            }, 1000);
            return () => {
                clearTimeout(handler);
            };
        }
    }, [GetProjectList, selectedOptions]);


    const onChange = useCallback(
        (value, key) => {
            const updatedValue = value === undefined ? "" : value;
            setSelectedOptions((prev) => ({ ...prev, [key]: updatedValue }));
        },
        []
    );

    const debounceFetcher = useMemo(() => {
        let loadOptions = [];
        loadOptions = async (value) => {
            await GetClientList(1, { key: value });
        };

        return debounce(loadOptions, 800);
    }, [GetClientList]);

    const columns = [
        {
            title: "Project Title",
            dataIndex: "project_title",
            key: "project_title",
            // width: "20%",
        },
    ]

    const dataSource = projectList?.map((project) => ({
        key: project?._id,
        project_title: project?.project_title
    }))

    return (
        <Modal
            width={1000}
            centered
            title={
                <div className="modalHeader" style={{ fontSize: 18 }}>
                    Projects
                    <Divider className="m-2" />
                </div>
            }
            open={isOpenProject}
            onCancel={handleClose}

            footer={
                <div className="d-flex justify-content-end align-items-center">

                    <div>
                        <Button
                            className="me-2"
                            key="back"
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            // disabled={!selectedProjectId}
                            className="viewButton text-end"
                            style={{ height: "32px" }}
                            onClick={() => handleSubmit()}
                        >
                            Submit{" "}
                            <Spin
                                spinning={loading}
                                className="ms-2"
                                size="small"
                                indicator={<LoadingOutlined />}
                            />
                        </Button>
                    </div>
                </div>
            }
        >
            <div>
                <Input
                    placeholder="Search"
                    value={selectedOptions?.key}
                    onChange={(e) => {
                        callSearch.current = true
                        setSelectedOptions({ ...selectedOptions, page: 1, key: e.target.value })
                    }
                    }
                    prefix={<SearchOutlined />}
                    // style={{ marginBottom: 16 }}
                    size="large"
                />
            </div>
            <Divider className="m-2" />
            <Row className="mt-2 g-2">
                <Col md={3}>
                    <Input
                        size="large"
                        placeholder="Project Name"
                        className="input-size"
                        value={selectedOptions?.project_name}
                        onChange={(e) =>
                            onChange(e.target.value, "project_name")
                        }
                        style={{ height: "40px" }}
                        allowClear
                    />
                </Col>
                <Col md={3}>
                    {/* <Input
                        size="large"
                        placeholder=" client name"
                        className="input-size"
                        value={selectedOptions?.client_name}
                        onChange={(e) =>
                            onChange(e.target.value, "client_name")
                        }
                        style={{ height: "40px" }}
                        allowClear
                    /> */}
                    <Select
                        style={{ width: "100%" }}
                        placeholder="Client Name"
                        className="input-size"
                        onSearch={(value) => debounceFetcher(value)}
                        size="large"
                        mode="multiple"
                        showSearch
                        value={selectedOptions?.client_name || null}
                        onChange={(value) => onChange(value, "client_name")}
                        filterOption={(input, option) =>
                            option?.props?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) !== -1
                        }
                        allowClear
                    >
                        {clientList &&
                            clientList?.map((option, index) => (
                                <Option value={option?.companyName} key={index}>
                                    {option?.companyName}
                                </Option>
                            ))}
                    </Select>
                </Col >
                {userData?.data?.roleData?.[0]?.name === "Super Admin" && (
                    <Col md={3}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Manager"
                            className="input-size"
                            size="large"
                            mode="multiple"
                            showSearch
                            value={selectedOptions?.manager_id || null}
                            onChange={(value) => onChange(value, "manager_id")}
                            filterOption={(input, option) =>
                                option?.props?.children
                                    ?.toLowerCase()
                                    .indexOf(input?.toLowerCase()) !== -1
                            }
                            allowClear
                        >
                            {managerFilterList &&
                                managerFilterList?.map((option, index) => (
                                    <Option value={option?._id} key={index}>
                                        {option?.manager}
                                    </Option>
                                ))}
                        </Select>
                    </Col>
                )}
                {(userData?.data?.roleData?.[0]?.name === "Super Admin" ||
                    userData?.data?.roleData?.[0]?.name === "Manager") && (
                        <Col md={2}>
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Team Member"
                                className="input-size"
                                mode="multiple"
                                size="large"
                                showSearch
                                value={selectedOptions?.added_by || null}
                                onChange={(value) => onChange(value, "added_by")}
                                filterOption={(input, option) =>
                                    option?.props?.children
                                        ?.toLowerCase()
                                        .indexOf(input?.toLowerCase()) !== -1
                                }
                                allowClear
                            >
                                {teamMemberList &&
                                    teamMemberList?.map((option, index) => (
                                        <Option value={option?._id} key={index}>
                                            {option?.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                    )}

                <Col md={1}>
                    <button
                        className="viewButton btn"
                        onClick={() => {
                            setSelectedOptions({ ...selectedOptions, page: 1 })
                            GetProjectList({ ...selectedOptions, page: 1 });
                        }}
                    >
                        Search
                    </button>
                </Col>
            </Row>
            <Divider className="mb-3" />
            {/* <Checkbox.Group
                style={{ width: "100%", display: "block" }}
                value={checked}
                onChange={(checkedValues) => {
                    setChecked(checkedValues);
                }}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={projectList}
                    pagination={{
                        current: selectedOptions.page,
                        total: projectListCount,
                        onChange: (page) => {
                            GetProjectList({ ...selectedOptions, page: page })
                            setSelectedOptions({
                                ...selectedOptions,
                                page: page
                            })
                        }

                    }}

                    renderItem={(item, index) => (
                        <List.Item className="cust_width"
                            style={{
                                borderWidth: "100%"
                            }}
                            // onClick={() => {
                            //     setChecked([...checked, item?._id]);
                            // }}
                            key={index}
                        >
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <Checkbox value={item?._id} />
                                <span >{item?.project_title}</span>
                                <span >{item?._id}</span>
                            </div>
                            {/* <List.Item.Meta
                                avatar={<Checkbox value={item?._id} />}
                                title={<span >{item?.project_title}</span>}
                            /> */}
            {/* </List.Item>
    )
}
                />
            </Checkbox.Group > */}
            <Table
                columns={columns}
                dataSource={dataSource}
                rowSelection={rowSelection}

                pagination={{
                    current: selectedOptions.page,
                    total: projectListCount,
                    onChange: (page) => {
                        GetProjectList({ ...selectedOptions, page: page })
                        setSelectedOptions({
                            ...selectedOptions,
                            page: page
                        })
                    },
                    showSizeChanger: false
                }}
                loading={loadingProjects}
            />
        </Modal >
    )

};
