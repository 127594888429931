import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  DatePicker,
  notification,
  Pagination,
  Segmented,
  Select,
  Spin,
  Table,
  Tooltip
} from "antd";
import dayjs from "dayjs";
import _ from 'lodash';
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AvatarGroup from "react-avatar-group";
import { Col, Modal, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import { IoInformationCircleOutline } from "react-icons/io5";
import {
  MdDelete,
  MdModeEdit,
  MdOutlineClose
} from "react-icons/md";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import API_PATH from "../../Constants/api-path";
import { Calculate_days } from "../../Utils/util";
import ProjectCallDetailsModal from "../projects/ProjectCallDetailsModal";
import AddProjectModal from "./AddProjectModal";
import CloseProject from "./CloseProject";
import StatisticContainer from "./Components/StatisticContainer";
import DeleteModal from "./DeleteModal";

const { Option } = Select;
const { RangePicker } = DatePicker

function CompletedProjects() {
  let queryParams = queryString.parse(window.location.search);
  const [show, setShow] = useState(false);
  const [handleCloseProject, setCloseProject] = useState(false);
  const [handleDeleteProject, setDeleteProject] = useState(false);
  const [modalStatus, setModalStatus] = useState(2);
  const handleClose = () => {
    setTeamIdEdit("")
    setShow(false)
  };
  const handleShow = (status) => {
    setModalStatus(status); // Set the status based on the button clicked
    setShow(true);
  };
  const [teamId, setTeamId] = useState("");

  const [completeProject, setcompleteProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teamIdClosed,] = useState("");
  const [teamIdEdit, setTeamIdEdit] = useState("");
  const [managerList, setManagerList] = useState([]);
  const [managerFilterList, setManagerFilterList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [projectFilterList, setProjectFilterList] = useState([]);
  const [clientFilterList, setClientFilterList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    project_name:
      !["", undefined, "undefined"]?.includes(queryParams?.project_name) ? Array?.isArray(queryParams?.project_name) ? queryParams?.project_name : queryParams?.project_name?.split(", ") : [],
    client_name:
      !["", undefined, "undefined"]?.includes(queryParams?.client_name) ? Array?.isArray(queryParams?.client_name) ? queryParams?.client_name : queryParams?.client_name?.split(", ") : [],
    added_by: !["", undefined, "undefined"]?.includes(queryParams?.added_by)
      ? Array?.isArray(queryParams?.added_by) ? queryParams?.added_by : queryParams?.added_by?.split(", ") : [],
    manager_id: !["", undefined, "undefined"]?.includes(queryParams?.manager_id)
      ? Array?.isArray(queryParams?.manager_id) ? queryParams?.manager_id : queryParams?.manager_id?.split(", ") : [],
    date:
      !["", undefined, "undefined"]?.includes(queryParams?.sdate) &&
        !["", undefined, "undefined"]?.includes(queryParams?.edate)
        ? [queryParams?.sdate, queryParams?.edate]
        : [],
  });

  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    constructUrl(selectedOptions, pageNumber);
    GetCompleteProjectList(pageNumber, selectedOptions);
  };
  const [open, setOpen] = useState({
    id: "",
    isOpen: false
  });
  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const history = useNavigate();

  const handleOptionChange = useCallback(
    (option) => {
      history(
        option === "Closed Projects"
          ? "/dashboard/team/completed-projects"
          : option === "On-hold Projects" ? "/dashboard/team/hold-projects" : "/dashboard/team/projects"
      );
      // setSelectedOption(option);
    },
    [history]
  );

  const userData = JSON.parse(localStorage.getItem("userData"));

  // const dataSource = [
  //   {
  //     key: "1",
  //     project: (
  //       <>
  //         <NavLink to={"/dashboard/team/project-overview"}>
  //           <Tooltip color="#5746EB" title="Retail Banking" placement="topLeft">
  //             <h5 className="projectTitle">Retail Banking</h5>
  //           </Tooltip>
  //           <small className="text-muted">Created 01 Aug 2023</small>
  //         </NavLink>
  //       </>
  //     ),
  //     startDate: (
  //       <>
  //         <NavLink to={"/dashboard/team/project-overview"}>
  //           <p>01 Aug 2023</p>
  //           <small style={{ color: "#5746EB" }}>1 Day</small>
  //         </NavLink>
  //       </>
  //     ),
  //     clientName: "Stryber",
  //     addedBy: (
  //       <>
  //         <AvatarGroup
  //           avatars={["Vivan Kaushik"]}
  //           uppercase={true}
  //           className="team-info"
  //           initialCharacters={2}
  //           fontColor="#c40a36"
  //           max={2}
  //           bold={true}
  //           size={40}
  //           fontSize=".38"
  //           backgroundColor="#efefef"
  //           tooltipStyle={{
  //             backgroundColor: "#5746EB"
  //           }}
  //         />
  //       </>
  //     ),
  //     team: (
  //       <>
  //         <AvatarGroup
  //           avatars={["Vivan Kaushik", "Shruti Jain", "Akshay Jain"]}
  //           uppercase={true}
  //           className="team-info"
  //           initialCharacters={2}
  //           fontColor="#c40a36"
  //           max={2}
  //           bold={true}
  //           size={30}
  //           fontSize=".38"
  //           backgroundColor="#efefef"
  //           tooltipStyle={{
  //             backgroundColor: "#5746EB"
  //           }}
  //         />
  //       </>
  //     ),
  //     expertCount: 0,
  //     action: (
  //       <>
  //         <Tooltip color="#5746EB" title="Delete Project" placement="topRight">
  //           <MdDelete
  //             onClick={() => setDeleteProject(true)}
  //             style={{ fontSize: "20px" }}
  //           />
  //         </Tooltip>
  //       </>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     project: (
  //       <>
  //         <Tooltip
  //           color="#5746EB"
  //           title="H2 / Project Olivine"
  //           placement="topLeft"
  //         >
  //           <h5 className="projectTitle">H2 / Project Olivine</h5>
  //         </Tooltip>
  //         <small className="text-muted">Created 01 Aug 2023</small>
  //       </>
  //     ),
  //     startDate: (
  //       <>
  //         <p>01 Aug 2023</p>
  //         <small style={{ color: "#5746EB" }}>1 Day</small>
  //       </>
  //     ),
  //     clientName: "NRI",
  //     addedBy: (
  //       <AvatarGroup
  //         avatars={["Akshay Jain"]}
  //         uppercase={true}
  //         className="team-info"
  //         initialCharacters={2}
  //         fontColor="#c40a36"
  //         max={2}
  //         bold={true}
  //         size={40}
  //         fontSize=".38"
  //         backgroundColor="#efefef"
  //         tooltipStyle={{
  //           backgroundColor: "#5746EB"
  //         }}
  //       />
  //     ),
  //     team: (
  //       <AvatarGroup
  //         avatars={["Vivan Kaushik", "Shruti Jain", "Akshay Jain"]}
  //         uppercase={true}
  //         className="team-info"
  //         initialCharacters={2}
  //         fontColor="#c40a36"
  //         max={2}
  //         bold={true}
  //         size={30}
  //         fontSize=".38"
  //         backgroundColor="#efefef"
  //         tooltipStyle={{
  //           backgroundColor: "#5746EB"
  //         }}
  //       />
  //     ),
  //     expertCount: 0,
  //     action: (
  //       <>
  //         <Tooltip color="#5746EB" title="Delete Project" placement="topRight">
  //           <MdDelete
  //             onClick={() => setDeleteProject(true)}
  //             style={{ fontSize: "20px" }}
  //           />
  //         </Tooltip>
  //       </>
  //     ),
  //   },
  // ];

  const convertDate = useCallback((date) => {
    if (!["", "undefined", undefined]?.includes(date)) {
      var d = new Date(date);
      // Add 1 day to the date
      d.setTime(d.getTime());
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  }, []);



  const GetCompleteProjectList = useCallback(async (page, selectedOptions) => {
    setLoading(true);
    try {
      let object = {};

      if (selectedOptions?.project_name) {
        object.project_name = selectedOptions?.project_name;
      }

      if (selectedOptions?.client_name) {
        object.client_name = selectedOptions?.client_name;
      }

      if (selectedOptions?.added_by) {
        object.added_by = selectedOptions?.added_by;
      }
      if (selectedOptions?.manager_id) {
        object.manager_id = selectedOptions?.manager_id;
      }

      // if (selectedOptions?.date) {
      //   object.date = selectedOptions?.date;
      // }
      if (selectedOptions?.date?.length > 0 && !["", undefined, "undefined"]?.selectedOptions?.date?.[0] && !["", undefined, "undefined"]?.selectedOptions?.date?.[1]) {
        object.sdate = selectedOptions?.date?.[0];
        object.edate = selectedOptions?.date?.[1];
      }

      const queryParams = new URLSearchParams(object);

      const res = await fetch(
        `${API_PATH.TEAM_COMPLETE_PROJECT}/${userData?.data?.email
        }?page=${page}&limit=10&${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const result = await res.json();
      if (result) {
        setcompleteProject(result?.data);
        setManagerFilterList(result?.allManagers)
        setProjectFilterList(result?.projectsName);
        setClientFilterList(result?.clientName);
        setTeamMemberList(result?.allTeams)
        setCallPage(result?.countData);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }, [userData?.data?.email, userData?.token]);

  const GetManagerList = useCallback(async () => {
    // setLoading(true);
    try {
      const res = await fetch(`${API_PATH.MANAGER_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setManagerList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }


  }, [userData?.token]);

  useEffect(() => {
    GetManagerList();
  }, [GetManagerList]);

  const goToDetailsPage = useCallback((project_id, status) => {
    history({
      pathname: "/dashboard/team/project-overview",
      search: `?${createSearchParams({ project_id, status })}`,
    });
  }, [history]);

  // useEffect(() => {
  //   GetCompleteProjectList(currentPage, selectedOptions);
  // }, [GetCompleteProjectList, currentPage, selectedOptions]);
  const callonce = useRef(false)
  useEffect(() => {
    if (!callonce.current) {
      callonce.current = true
      GetCompleteProjectList(currentPage, selectedOptions);
    }
  }, [GetCompleteProjectList, currentPage, selectedOptions]);

  useEffect(() => {
    let queryParams = queryString.parse(window.location.search);
    if (queryParams.page && !isNaN(parseInt(queryParams.page))) {
      setCurrentPage(parseInt(queryParams.page));
    } else {
      setCurrentPage(1);
    }
  }, []);

  const filteredData = useMemo(() => completeProject?.filter((item) => item.status === 2), [completeProject])

  const getTeamId = useCallback((_id) => {
    setTeamId(_id);
  }, []);

  const onChange = useCallback((value, key) => {
    const updatedValue = value === undefined ? '' : value;

    setSelectedOptions((prev) => ({ ...prev, [key]: updatedValue }));

    const queryParams = queryString.parse(window.location.search);
    queryParams[key] = JSON.stringify(updatedValue);

    history({
      pathname: "/dashboard/team/completed-projects",
      search: `?${queryString.stringify(queryParams)}`,
    });
  }, [history]);


  // const getTeamIdClosed = useCallback((_id) => {
  //   setTeamIdClosed(_id);
  // }, []);

  const getTeamIdEdit = useCallback((_id) => {
    setTeamIdEdit(_id);
  }, []);

  const constructUrl = useCallback((selectedOptions, pageNo) => {
    const { project_name, client_name, added_by, date, manager_id } = selectedOptions || {};

    const projectParam = Array.isArray(project_name)
      ? project_name.join(", ")
      : project_name;
    const companyParam = Array.isArray(client_name)
      ? client_name.join(", ")
      : client_name;
    const teamMemberParams = Array.isArray(added_by)
      ? added_by.join(", ")
      : added_by;
    const managerParams = Array.isArray(manager_id)
      ? manager_id.join(", ")
      : manager_id;
    // const clientParam = Array.isArray(date) ? date.join(", ") : date;
    const clientPage = Array.isArray(pageNo) ? date.join(", ") : pageNo;
    const sdate = Array.isArray(date) && !["", undefined, "undefined"]?.includes(date[0]) ? JSON.stringify(date[0]) : "";
    const edate = Array.isArray(date) && !["", undefined, "undefined"]?.includes(date[1]) ? JSON.stringify(date[1]) : "";

    const urlParams = [
      `project_name=${encodeURIComponent(projectParam)}`,
      `client_name=${encodeURIComponent(companyParam)}`,
      `added_by=${encodeURIComponent(teamMemberParams)}`,
      `manager_id=${encodeURIComponent(managerParams)}`,
      `sdate=${encodeURIComponent(sdate)}`,
      `edate=${encodeURIComponent(edate)}`,
      `page=${encodeURIComponent(clientPage)}`,

    ];

    return history({
      pathname: `/dashboard/team/completed-projects`,
      search: `?${urlParams.join("&")}`,
    });
  }, [history]);

  useEffect(() => {
    if (selectedOptions) {
      constructUrl(selectedOptions, currentPage);
    }
  }, [selectedOptions, currentPage, constructUrl]);

  // const menu = useCallback((_id) => (
  //   <Menu>
  //     <Menu.Item
  //       key="edit"
  //       onClick={() => {
  //         handleShow();
  //         getTeamIdEdit(_id);
  //       }}
  //     >
  //       <span>
  //         <MdModeEditOutline style={{ marginRight: "8px" }} />
  //         Edit
  //       </span>
  //     </Menu.Item>
  //     <Menu.Item
  //       key="delete"
  //       onClick={() => {
  //         setDeleteProject(true);
  //         getTeamId(_id);
  //       }}
  //     >
  //       <span>
  //         <MdDelete style={{ marginRight: "8px" }} />
  //         Delete Project
  //       </span>
  //     </Menu.Item>
  //   </Menu>
  // ), [getTeamId, getTeamIdEdit]);

  const columns = [
    {
      title: "Project",
      dataIndex: "project_title",
      key: "project_title",
      width: "200px",
      render: (text, record) => {
        // Truncate the project title to 6 words
        return (
          <>
            <div>
              <div className="d-flex align-items-center gap-1">
                <span>
                  <Tooltip
                    color="#5746EB"
                    title={record?.project_title}
                    placement="topLeft"
                  >
                    <h5
                      onClick={() => goToDetailsPage(record?._id, modalStatus)}
                      className="projectTitle"
                    >
                      {_.truncate(record?.project_title, {
                        length: 30,
                        omission: "...",
                      }) || "-"}
                    </h5>
                  </Tooltip>
                </span>
                <span className="projectTitle align-middle">
                  <Tooltip
                    placement="right"
                    overlayClassName="projectStatesPopover"
                    trigger={"click"}
                    title={
                      <>
                        <h4
                          style={{
                            fontSize: "14px",
                            color: "rgb(87, 70, 236)",
                            margin: "6px 0",
                            fontWeight: 600,
                          }}
                        >
                          Project Statistics
                        </h4>
                        <StatisticContainer record={record} />
                      </>
                    }
                  >
                    <IoInformationCircleOutline />
                  </Tooltip>
                </span>
              </div>
              <div className="d-flex justify-content-between align-align-items-center">
                <small className="text-muted">
                  <span className="d-flex gap-2">
                    <span>{convertDate(record?.project_start)}</span>
                  </span>
                  {Calculate_days(
                    record?.project_start,
                    record?.project_deadline
                  )}
                </small>

              </div>
            </div>
          </>
        );
      },
    },

    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
      align: "center",
      width: "100px",
      render: (name, data) => {
        return <Link className="projectTitle" to={`/dashboard/clients?view=true&client_id=${data?.company_id}`}>{name}</Link>
      }
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      align: "center",
      render: (record) => (
        <span className="d-flex justify-content-center avtrgrp">
          <AvatarGroup
            avatars={record?.map((rec) => rec?.name) || ["Nextyn"]}
            uppercase={true}
            initialCharacters={2}
            fontColor="#f33636"
            max={2}
            displayAllOnHover={true}
            size={30}
            fontSize=".38"
            backgroundColor="#EFEFEF"
            tooltipStyle={{
              backgroundColor: "#5746EB"
            }}
          />
        </span>
      ),
    },

    {
      title: "Team",
      // dataIndex: "team_count",
      // key: "name",
      render: (record) => (
        <span className="d-flex justify-content-center avtrgrp">
          {/* {record?.invite_team_dashboard?.split(",")?.map} */}
          {record?.invite_team_dashboard_name ? (
            <AvatarGroup
              avatars={record?.invite_team_dashboard_name
                ?.split(",")
                // ?.filter(
                //   (item) =>
                //     item.toLowerCase() !== userData?.data?.name?.toLowerCase()
                // )
              }
              uppercase={true}
              initialCharacters={2}
              fontColor="#f33636"
              max={2}
              displayAllOnHover={true}
              size={30}
              fontSize=".38"
              backgroundColor="#EFEFEF"
              tooltipStyle={{
                backgroundColor: "#5746EB"
              }}
            />
          ) : (
            " -"
          )}
        </span>
      ),
      align: "center",
    },
    {
      title: (
        <span>
          Expected
          <br />
          Calls
        </span>
      ),
      dataIndex: "expected_calls",
      key: "expected_calls",
      width: "50px",
      render: (text, record) => {
        return (
          <>
            <div
              className="d-flex justify-content-center"
              onClick={() => goToDetailsPage(record?._id)}
            >
              <p>{record?.no_of_calls_expected || "-"}</p>
            </div>
          </>
        );
      },
      align: "center",
    },
    {
      title: (
        <span>
          Profiles
          <br />
          Sent
        </span>
      ),
      dataIndex: "no_of_profiles_sent",
      key: "no_of_profiles_sent",
      align: "center",
      width: "50px",
      render: (text, record) => {
        return (
          <>
            <div onClick={() => goToDetailsPage(record?._id)}>
              <p>{record?.no_of_profiles_sent || "-"}</p>
            </div>
          </>
        );
      },
    },
    {
      title: (
        <span>
          Completed
          <br />
          Calls
        </span>
      ),
      dataIndex: "no_of_calls_completed",
      key: "no_of_calls_completed",
      align: "center",
      width: "50px",
      render: (text, record) => {
        return (
          <>
            <div className="link-text" onClick={() => {
              if (record?.no_of_calls_completed > 0) {
                setOpen({
                  id: record?._id,
                  isOpen: true
                })
              }
            }}>
              <p>{record?.no_of_calls_completed || "-"}</p>
            </div>
          </>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (_id) => (
        // <Dropdown overlay={menu(_id)} trigger={["click"]}>
        //   <MoreOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
        // </Dropdown>
        <div className="d-flex justify-content-center expertActions align-items-center gap-1">
          <Tooltip placement="top" title={"View"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => goToDetailsPage(_id)}
              >
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                ></i>
              </button>
            </span>
          </Tooltip>
          <Tooltip placement="top" title={"Edit"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                onClick={() => {
                  handleShow();
                  getTeamIdEdit(_id);
                }}
              >
                <MdModeEdit />
              </button>
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Delete" color="#5746EB">
            <button
              size="large"
              className="viewBttnExpert"
              onClick={() => {
                setDeleteProject(true);
                getTeamId(_id);
              }}
            >
              <MdDelete />
            </button>
          </Tooltip>
        </div>
      ),
      align: "center",
    },
  ];

  return (
    <>
      {/* <AdminDashboard /> */}
      <Helmet>
        <title>{"Nextyn | Team Projects"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="bk-div float-start w-100">
              <div className="body-expart-div projects-pages">
                <div className="d-md-flex project-sec-1 top-ps-seection justify-content-between align-items-center m-queries">
                  <h2> My Projects </h2>
                  <Segmented
                    size="large"
                    defaultValue={"Closed Projects"}
                    options={["Active Projects", "On-hold Projects", "Closed Projects"]}
                    onChange={handleOptionChange}
                  />
                  <div>
                    <button
                      className="viewButton btn"
                      onClick={() => {
                        handleShow(2);
                        setTeamIdEdit("");
                      }}
                    >
                      Add Project
                    </button>
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <Row className="mt-3  g-2 flex align-items-center">
                    <Col md={11}>
                      <Row md={12}>
                        <Col >
                          {/* <Input
                            size="large"
                            placeholder="Project Name"
                            className="input-size"
                            value={selectedOptions?.project_name}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                callonce.current = false
                              } onChange(e.target.value, "project_name")
                            }}
                            style={{ height: "40px" }}
                            allowClear
                          /> */}
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Project Name"
                            className="input-size"
                            size="large"
                            mode="multiple"
                            showSearch
                            value={selectedOptions?.project_name || null}
                            onChange={(value) => {
                              onChange(value, "project_name")
                            }
                            }
                            onClear={() => {
                              callonce.current = false;
                            }}
                            filterOption={(input, option) =>
                              option?.props?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) !== -1
                            }
                            allowClear
                          >
                            {projectFilterList &&
                              projectFilterList?.map((option, index) => (
                                <Option value={option?._id} key={index}>
                                  {option?.project_title}
                                </Option>
                              ))}
                          </Select>
                        </Col>
                        <Col >
                          {/* <Input
                            size="large"
                            placeholder="Client Name"
                            className="input-size"
                            value={selectedOptions?.client_name}
                            onChange={(e) => {
                              if (e.target.value === "") {
                                callonce.current = false
                              } onChange(e.target.value, "client_name")
                            }}
                            style={{ height: "40px" }}
                            allowClear
                          /> */}
                          <Select
                            style={{ width: "100%" }}
                            placeholder="Client Name"
                            className="input-size"
                            size="large"
                            mode="multiple"
                            showSearch
                            value={selectedOptions?.client_name || null}
                            onChange={(value) => {
                              onChange(value, "client_name")
                            }
                            }
                            onClear={() => {
                              callonce.current = false;
                            }}
                            filterOption={(input, option) =>
                              option?.props?.children
                                ?.toLowerCase()
                                .indexOf(input?.toLowerCase()) !== -1
                            }
                            allowClear
                          >
                            {clientFilterList &&
                              clientFilterList?.map((option, index) => (
                                <Option value={option?._id} key={index}>
                                  {option?.companyName}
                                </Option>
                              ))}
                          </Select>
                        </Col>
                        {userData?.data?.roleData?.[0]?.name === "Super Admin" &&
                          <Col >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Manager"
                              mode="multiple"
                              className="input-size"
                              size="large"
                              showSearch
                              value={selectedOptions?.manager_id || null}
                              onClear={() => {
                                callonce.current = false
                              }}
                              onChange={(value) => onChange(value, "manager_id")}
                              filterOption={(input, option) =>
                                option?.props?.children
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) !== -1
                              }
                              allowClear
                            >
                              {managerFilterList &&
                                managerFilterList?.map((option, index) => (
                                  <Option value={option?._id} key={index}>
                                    {option?.manager}
                                  </Option>
                                ))
                              }
                            </Select>
                          </Col>}
                        {(userData?.data?.roleData?.[0]?.name === "Super Admin" || userData?.data?.roleData?.[0]?.name === "Manager") &&
                          <Col >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Team Member"
                              mode="multiple"
                              className="input-size"
                              size="large"
                              showSearch
                              onClear={() => {
                                callonce.current = false
                              }}
                              value={selectedOptions?.added_by || null}
                              onChange={(value) => onChange(value, "added_by")}
                              filterOption={(input, option) =>
                                option?.props?.children
                                  ?.toLowerCase()
                                  .indexOf(input?.toLowerCase()) !== -1
                              }
                              allowClear
                            >
                              {teamMemberList &&
                                teamMemberList?.map((option, index) => (
                                  <Option value={option?._id} key={index}>
                                    {option?.name}
                                  </Option>
                                ))}
                            </Select>
                          </Col>}
                        <Col md={4}>
                          <RangePicker
                            size="large"
                            style={{ width: "100%", height: "40px" }}
                            format="YYYY-MM-DD"
                            className="input-size"
                            value={selectedOptions?.date?.[0] && selectedOptions?.date?.[1] ? [dayjs(selectedOptions?.date?.[0], "YYYY-MM-DD"), dayjs(selectedOptions?.date?.[1], "YYYY-MM-DD")] : []}
                            onChange={(date, dateString) => {
                              if (date === null) {
                                callonce.current = false
                              }
                              onChange(dateString, "date")
                            }
                            } allowClear
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}>
                      <button
                        className="viewButton btn"
                        style={{
                          float: "right"
                        }}
                        onClick={() => {
                          GetCompleteProjectList(currentPage, selectedOptions);
                        }}
                      >
                        Search
                      </button>
                    </Col>
                  </Row>


                  {loading ?
                    <Row className="mt-3 pt-3">
                      <Col className="d-flex justify-content-center align-items-center h-40">
                        <Spin />
                      </Col>
                    </Row>
                    : <Row className="mt-3">
                      <Col>
                        <Table
                          dataSource={filteredData}
                          columns={columns}
                          // loading={loading}
                          size="large"
                          pagination={false}
                          scroll={{ x: 800 }}
                        />
                        <Pagination
                          {...paginationSettings}
                          showSizeChanger={false}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                            textAlign: "right",
                          }}
                        />
                      </Col>
                    </Row>}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <Modal
        show={handleCloseProject}
        onHide={() => setCloseProject(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Modal.Body className="position-relative">
          <MdOutlineClose
            className="position-absolute"
            style={{ right: "15px", top: "15px", fontSize: "25px", cursor: "pointer" }}
            onClick={() => setCloseProject(false)}
          />
          <div className="text-left">
            <h4 className="modalHeader">Close Project</h4>
          </div>
          <div>
            Are you sure you want to close this project ?
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="secondaryBttn btn"
              onClick={() => setCloseProject(false)}
            >
              No
            </button>
            <button className="viewButton btn ms-2">Yes</button>
          </div>
        </Modal.Body>
      </Modal>

      <AddProjectModal
        show={show}
        handleClose={handleClose}
        teamIdEdit={teamIdEdit}
        managerList={managerList}
        selectedOptions={selectedOptions}
        currentPage={currentPage}
        GetCompleteProjectList={GetCompleteProjectList}
      />

      <DeleteModal
        show={handleDeleteProject}
        setShow={setDeleteProject}
        type={"Project"}
        teamId={teamId}
        GetCompleteProjectList={GetCompleteProjectList}
      />

      <CloseProject
        show={handleCloseProject}
        setShow={setCloseProject}
        status={modalStatus}
        teamIdClosed={teamIdClosed}
        GetCompleteProjectList={GetCompleteProjectList}
        updatedStatus={2}
      />
      <ProjectCallDetailsModal isOpen={open?.isOpen} project_id={open?.id} setIsOpen={(val) => {
        setOpen({
          id: "",
          isOpen: val
        })
      }} />
    </>
  );
}

export default CompletedProjects;
